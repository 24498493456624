import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
//import 'slick-carousel';

//import Headroom from "headroom.js";

/**
 * Anonymous function is autoexecutable.
 */
(function () {
  

})();